import './App.css';
function App() {
  const CA = "sMkcJbU3B3AnUAv7MqQK8SEmRi371C85Fmvrzkqpump"
  const openTelegram = () => {
    window.open("http://t.me/sapesolana", "_blank");
  };
  const openX = () => {
    window.open("http://x.com/sape_solana", "_blank");
  };
  const openBuy = () => {
    window.open("http://raydium.io/swap/?inputMint=sol&outputMint=sMkcJbU3B3AnUAv7MqQK8SEmRi371C85Fmvrzkqpump", "_blank");
  };
  function handleCopyClick() {
    navigator.clipboard.writeText(CA).then(() => {
      console.log('Text copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    })
  };
  function repeatWord(word) {
    return word.repeat(50);
  }

  return (
    <div className="App">
      <div className='Header'></div>
      <div className='SideActionContainer'>
        <div className='SideActions'>
          <div className='BuyBtn' onClick={openBuy}> BUY $SAPE</div>
          <div className='SMContainer'>
            <div className='SMIcon XLogo' onClick={openX} />
            <div className='SMIcon TelegramLogo' onClick={openTelegram} />
          </div>
        </div>
      </div>
      <div className='SideDescriptor'>
        <div className='SideDescriptorInfoC'>
          <h1>Meet the Sape</h1>
          <h2>a towering beast with a fierce temper, hurling barrels at anything that dares to disturb his peace!</h2>
        </div>
        <img src={require('./assets/1.png')} alt='sape' />
      </div>
      <div className='SideAnimation' />
      <div className='SideVertical'></div>
      <div className='buffer' />
      <div className='Tokenomics'>
        <h1 className='TitleText paddingtop5vw '>Tokenomics</h1>
        <div className='TokenomicsDataContainer'>
          <div className='TokenomicsDataItem'>
            <h2>Token Supply</h2>
            <h3>1,000,000,000 Tokens</h3>
          </div>
          <div className='TokenomicsDataItem'>
            <h2>CA</h2>
            <h3>{CA}</h3>
            <div className='CopyBtn' onClick={handleCopyClick}>Copy</div>
          </div>
          <div className='BuyBtn' onClick={openBuy}> BUY $SAPE</div>

        </div>
      </div>
      <div className='Seperator'>
        <h1 className='Ticker'>{repeatWord('$SAPE\xa0\xa0')}</h1>
      </div>
      <div className='Guide'>
        <h1 className='TitleText'>How to buy</h1>
        <div className='GuideDataContainer'>
          <div className='GuideDataItem'>
            <h1>1. Create a wallet with Phantom</h1>
            <h3>Visit <a href="https://phantom.app/" target="_blank" rel="noreferrer" className='PhantomLink'>Phantom.App</a> and follow the simple steps to create a new account with the phantom app or browser extension.</h3>
          </div>
          <div className='GuideDataItem'>
            <h1>2. Get some $SOL</h1>
            <h3>Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice.</h3>
          </div>
          <div className='GuideDataItem'>
            <h1>3. Swap $SOL for $SAPE</h1>
            <h3>Tap the SWAP icon in your Phantom wallet and paste the $SAPE token address. Swap your $SOL for $SAPE.</h3>
          </div>
          <div className='GuideDataItem'>
            <h1>4. You are now an $SAPE holder!</h1>
            <h3>Welcome to the SAPE troop!</h3>
          </div>
        </div>
      </div>
      <div className='Socials'>
        <h1 className='TitleText'>Socials</h1>
        <div className='SMContainer'>
          <div className='SMIcon XLogo' onClick={openX} />
          <div className='SMIcon TelegramLogo' onClick={openTelegram} />
        </div>

        <div className='imgContainer '>
          <div className='imgHolder r3degr img2'></div>
          <div className='imgHolder r5deg img1'></div>
          <div className='imgHolder r4degr img3'></div>
          <div className='imgHolder r2deg img4'></div>
        </div>
        <h2 className='Footer'>2024 © All Rights Reserved</h2>
      </div>
    </div>
  );
}

export default App;
